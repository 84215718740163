<template>
  <div>
    <v-container>
       <v-card flat tile>
        <div class="card-title-wrapper" id="sticky-nav">
          <v-card-title class="py-2 grey lighten-4 d-flex">
            <div class="text-center" @click="toDasboard">
              <v-icon>mdi-arrow-left</v-icon>
            </div>
            <div class="mx-auto">
              <span class="headline font-weight-light">{{dataType}}</span>
              <p class="text-center mb-0 mt-n1 caption">
                <span v-if="returnDataByType.length > 1">{{ returnDataByType.length }} registrations</span>
                <span v-else-if="returnDataByType.length == 1">{{ returnDataByType.length }} registration</span>
                <span v-else>no registrations</span>
              </p>
            </div>
          </v-card-title>
          <div class="white py-3 d-flex justify-center">
            <v-btn 
              @click="setDataType('Branch login registrations')" 
              :outlined="dataType !== 'Branch login registrations'"
              :class="{'white--text': dataType === 'Branch login registrations'}"
              class="mr-1 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('Branch completed')" 
              :outlined="dataType !== 'Branch completed'"
              :class="{'white--text': dataType === 'Branch completed'}"
              class="mr-5 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('My login registrations')" 
              :outlined="dataType !== 'My login registrations'" 
              :class="{'white--text': dataType === 'My login registrations'}"
              class="mr-1 text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-account</v-icon>

            </v-btn>
            <v-btn 
              @click="setDataType('My completed')" 
              :outlined="dataType !== 'My completed'" 
              :class="{'white--text': dataType === 'My completed'}"
              class="text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Mr Glyn Trott, Landlord of 10 Downing street is requesting activation on their online login -->

        <v-card-text v-if="returnDataByType.length" class="px-0 pt-0 mb-0">
          <v-card class="px-1" flat outlined tile v-for="item in returnDataByType" :key="item.OID">
            <v-card-text>
              <!-- <div class="body-1">{{item.Name}} </div>
              <div class="mb-5"><span class="font-weight-bold"></span> {{item.Address}}</div> -->
              <div>
                <span v-if="item.Name"><strong>{{item.Name}}</strong>, landord</span>
                <span v-if="!item.Name">Not specified landlord</span>
                <span v-if="item.Address"> of <strong>{{item.Address}}</strong> </span>
                is requesting activation on their online login.
              </div>
              <div class="mt-5 text-right text-md-left">
                <div class="font-weight-light">Contact details:</div>
                <div class="primary--text" v-if="item.EmailAddress">{{item.EmailAddress}}</div>
                <div class="font-weight-light" v-if="!item.EmailAddress">n/a</div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text class="mt-5" v-else>
          <NoDataMsg>
            Login registrations
          </NoDataMsg>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NoDataMsg from '@/components/MessageComponents/Slots/NoDataMsg.vue';

export default {
  name: 'login-registrations',
  components: {
    NoDataMsg
  },
  data() {
    return {
      dataType: 'Branch login registrations'
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    setDataType(type) {
      this.dataType = type
    },
    toDasboard() {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapState({
      branch: state => state.loginRegistrations.branch.data,
      branchCompleted: state => state.loginRegistrations.branchCompleted.data,
      staff: state => state.loginRegistrations.staff.data,
      staffCompleted: state => state.loginRegistrations.staffCompleted.data
    }),
    returnDataByType() {
      if(this.dataType === 'My login registrations') {return this.staff.Data}
      if(this.dataType === 'My completed') {return this.staffCompleted.Data}
      if(this.dataType === 'Branch completed') {return this.branchCompleted.Data}
      return this.branch.Data
    }
  }
}
</script>

<style>

</style>
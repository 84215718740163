<template>
  <LoginRegistrations />
</template>

<script>
import LoginRegistrations from '@/components/MessageComponents/LoginRegistrations.vue';

export default {
  name: 'login-registration-view',
  components: {
    LoginRegistrations
  },
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>